import defaultGamePassImage from "assets/img/finalScreen/gamepass-2x.jpg";
import defaultShopImage from "assets/img/finalScreen/shopDefault.jpg";

import baltimoreRavensVideo from "assets/img/videoImage/baltimoreRavensVideo.jpg";
import buffaloBillsVideo from "assets/img/videoImage/buffaloBillsVideo.jpg";
import cleavelandBrownsVideo from "assets/img/videoImage/cleavelandBrownsVideo.jpg";
import dallasCowboysVideo from "assets/img/videoImage/dallasCowboysVideo.jpg";
import greenBayPackersVideo from "assets/img/videoImage/greenBayPackersVideo.jpg";
import tennesseeTitansVideo from "assets/img/videoImage/tennesseeTitansVideo.jpg";
import indianapolisColtsVideo from "assets/img/videoImage/indianapolisColtsVideo.jpg";
import kansasCityCheifsVideo from "assets/img/videoImage/kansasCityCheifsVideo.jpg";
import lasAngelesRamsVideo from "assets/img/videoImage/lasAngelesRamsVideo.jpg";
import lasVegasRaidersVideo from "assets/img/videoImage/lasVegasRaidersVideo.jpg";
import miamiDolphinsVideo from "assets/img/videoImage/miamiDolphinsVideo.jpg";
import minnesotaVikingsVideo from "assets/img/videoImage/minnesotaVikingsVideo.jpg";
import newEnglandPatriotsVideo from "assets/img/videoImage/newEnglandPatriotsVideo.jpg";
import newOrleansSaintsVideo from "assets/img/videoImage/newOrleansSaintsVideo.jpg";
import newYorkGiantsVideo from "assets/img/videoImage/newYorkGiantsVideo.jpg";
import philadelphiaEaglesVideo from "assets/img/videoImage/philadelphiaEaglesVideo.jpg";
import arizonaCardinalsVideo from "assets/img/videoImage/arizonaCardinalsVideo.jpg";
import pittsburghSteelersVideo from "assets/img/videoImage/pittsburghSteelersVideo.jpg";
import sanFrancisco49ersVideo from "assets/img/videoImage/sanFrancisco49ersVideo.jpg";
import seattleSeahawksVideo from "assets/img/videoImage/seattleSeahawksVideo.jpg";
import tampaBayBuccaneersVideo from "assets/img/videoImage/tampaBayBuccaneersVideo.jpg";
import washingtonFootballClubVideo from "assets/img/videoImage/washingtonFootballClubVideo.jpg";
import cincinnatiBengalsVideo from "assets/img/videoImage/cincinnatiBengalsVideo.jpg";
import houstonTexansVideo from "assets/img/videoImage/houstonTexansVideo.jpg";
import losAngelesChargersVideo from "assets/img/videoImage/losAngelesChargersVideo.jpg";
import carolinaPanthersVideo from "assets/img/videoImage/carolinaPanthersVideo.jpg";
import jacksonvilleJaguarsVideo from "assets/img/videoImage/jacksonvilleJaguarsVideo.jpg";
import newYorkJetsVideo from "assets/img/videoImage/newYorkJetsVideo.jpg";
import denverBroncos from "assets/img/videoImage/denverBroncos.jpg";
import atlantaFalconsVideo from "assets/img/videoImage/atlantaFalconsVideo.jpg";
import detroitLionsVideo from "assets/img/videoImage/detroitLionsVideo.jpg";
import chicagoBearsVideo from "assets/img/videoImage/chicagoBearsVideo.jpg";

import baltimoreRavensImage from "assets/img/runnerUpImage/baltimoreRavens.png";
import buffaloBillsImage from "assets/img/runnerUpImage/buffaloBills.png";
import cleavelandBrownsImage from "assets/img/runnerUpImage/cleavelandBrowns.png";
import dallasCowboysImage from "assets/img/runnerUpImage/dallasCowboys.png";
import greenBayPackersImage from "assets/img/runnerUpImage/greenBayPackers.png";
import tennesseeTitansImage from "assets/img/runnerUpImage/tennesseeTitans.png";
import indianapolisColtsImage from "assets/img/runnerUpImage/indianapolisColts.png";
import kansasCityCheifsImage from "assets/img/runnerUpImage/kansasCityChiefs.png";
import lasAngelesRamsImage from "assets/img/runnerUpImage/lasAngelesRams.png";
import lasVegasRaidersImage from "assets/img/runnerUpImage/lasVegasRaiders.png";
import miamiDolphinsImage from "assets/img/runnerUpImage/miamiDolphins.png";
import minnesotaVikingsImage from "assets/img/runnerUpImage/minnesotaVikings.png";
import newEnglandPatriotsImage from "assets/img/runnerUpImage/newEnglandPatriots.png";
import newOrleansSaintsImage from "assets/img/runnerUpImage/newOrleansSaints.png";
import newYorkGiantsImage from "assets/img/runnerUpImage/newYorkGiants.png";
import philadelphiaEaglesImage from "assets/img/runnerUpImage/philadelphiaEagles.png";
import arizonaCardinalsImage from "assets/img/runnerUpImage/arizonaCardinals.png";
import pittsburghSteelersImage from "assets/img/runnerUpImage/pittsburghSteelers.png";
import sanFrancisco49ersImage from "assets/img/runnerUpImage/sanFrancisco49ers.png";
import seattleSeahawksImage from "assets/img/runnerUpImage/seattleSeahawks.png";
import tampaBayBuccaneersImage from "assets/img/runnerUpImage/tampaBayBuccaneers.png";
import washingtonFootballClubImage from "assets/img/runnerUpImage/washingtonFootballClub.png";
import cincinnatiBengalsImage from "assets/img/runnerUpImage/cincinnatiBengals.png";
import houstonTexansImage from "assets/img/runnerUpImage/houstonTexans.png";
import losAngelesChargersImage from "assets/img/runnerUpImage/losAngelesChargers.png";
import carolinaPanthersImage from "assets/img/runnerUpImage/carolinaPanthers.png";
import jacksonvilleJaguarsImage from "assets/img/runnerUpImage/jacksonvilleJaguars.png";
import newYorkJetsImage from "assets/img/runnerUpImage/newYorkJets.png";
import denverBroncosImage from "assets/img/runnerUpImage/denverBroncos.png";
import atlantaFalconsImage from "assets/img/runnerUpImage/atlantaFalcons.png";
import detroitLionsImage from "assets/img/runnerUpImage/detroitLions.png";
import chicagoBearsImage from "assets/img/runnerUpImage/chicagoBears.png";

import baltimoreRavensLogo from "assets/img/teamLogos/Ravens.svg";
import buffaloBillsLogo from "assets/img/teamLogos/Bills.svg";
import cleavelandBrownsLogo from "assets/img/teamLogos/Browns.svg";
import dallasCowboysLogo from "assets/img/teamLogos/Cowboys.svg";
import greenBayPackersLogo from "assets/img/teamLogos/Packers.svg";
import tennesseeTitansLogo from "assets/img/teamLogos/Titans.svg";
import indianapolisColtsLogo from "assets/img/teamLogos/Colts.svg";
import kansasCityCheifsLogo from "assets/img/teamLogos/Chiefs.svg";
import lasAngelesRamsLogo from "assets/img/teamLogos/Rams.svg";
import lasVegasRaidersLogo from "assets/img/teamLogos/Raiders.svg";
import miamiDolphinsLogo from "assets/img/teamLogos/Dolphins.svg";
import minnesotaVikingsLogo from "assets/img/teamLogos/Vikings.svg";
import newEnglandPatriotsLogo from "assets/img/teamLogos/Patriots.svg";
import newOrleansSaintsLogo from "assets/img/teamLogos/Saints.svg";
import newYorkGiantsLogo from "assets/img/teamLogos/Giants.svg";
import philadelphiaEaglesLogo from "assets/img/teamLogos/Eagles.svg";
import arizonaCardinalsLogo from "assets/img/teamLogos/Cardinals.svg";
import pittsburghSteelersLogo from "assets/img/teamLogos/Steelers.svg";
import sanFrancisco49ersLogo from "assets/img/teamLogos/49ers.svg";
import seattleSeahawksLogo from "assets/img/teamLogos/Seahawks.svg";
import tampaBayBuccaneersLogo from "assets/img/teamLogos/Buccaneers.svg";
import washingtonFootballClubLogo from "assets/img/teamLogos/Washington.svg";
import cincinnatiBengalsLogo from "assets/img/teamLogos/Bengals.svg";
import houstonTexansLogo from "assets/img/teamLogos/Texans.svg";
import losAngelesChargersLogo from "assets/img/teamLogos/Chargers.svg";
import carolinaPanthersLogo from "assets/img/teamLogos/Panthers.svg";
import jacksonvilleJaguarsLogo from "assets/img/teamLogos/Jaguars.svg";
import newYorkJetsLogo from "assets/img/teamLogos/Jets.svg";
import denverBroncosLogo from "assets/img/teamLogos/Broncos.svg";
import atlantaFalconsLogo from "assets/img/teamLogos/Falcons.svg";
import detroitLionsLogo from "assets/img/teamLogos/Lions.svg";
import chicagoBearsLogo from "assets/img/teamLogos/Bears.svg";

import baltimoreRavensBackground from "assets/img/teamBackground/Ravens.jpg";
import buffaloBillsBackground from "assets/img/teamBackground/Bills.jpg";
import cleavelandBrownsBackground from "assets/img/teamBackground/Browns.jpg";
import dallasCowboysBackground from "assets/img/teamBackground/Cowboys.jpg";
import greenBayPackersBackground from "assets/img/teamBackground/Packers.jpg";
import tennesseeTitansBackground from "assets/img/teamBackground/Titans.jpg";
import indianapolisColtsBackground from "assets/img/teamBackground/Colts.jpg";
import kansasCityCheifsBackground from "assets/img/teamBackground/Chiefs.jpg";
import lasAngelesRamsBackground from "assets/img/teamBackground/Rams.jpg";
import lasVegasRaidersBackground from "assets/img/teamBackground/Raiders.jpg";
import miamiDolphinsBackground from "assets/img/teamBackground/Dolphins.jpg";
import minnesotaVikingsBackground from "assets/img/teamBackground/Vikings.jpg";
import newEnglandPatriotsBackground from "assets/img/teamBackground/Patriots.jpg";
import newOrleansSaintsBackground from "assets/img/teamBackground/Saints.jpg";
import newYorkGiantsBackground from "assets/img/teamBackground/Giants.jpg";
import philadelphiaEaglesBackground from "assets/img/teamBackground/Eagles.jpg";
import arizonaCardinalsBackground from "assets/img/teamBackground/Cardinals.jpg";
import pittsburghSteelersBackground from "assets/img/teamBackground/Steelers.jpg";
import sanFrancisco49ersBackground from "assets/img/teamBackground/49ers.jpg";
import seattleSeahawksBackground from "assets/img/teamBackground/Seahawks.jpg";
import tampaBayBuccaneersBackground from "assets/img/teamBackground/Buccaneers.jpg";
import washingtonFootballClubBackground from "assets/img/teamBackground/Washington.jpg";
import cincinnatiBengalsBackground from "assets/img/teamBackground/Bengals.jpg";
import houstonTexansBackground from "assets/img/teamBackground/Texans.jpg";
import losAngelesChargersBackground from "assets/img/teamBackground/Chargers.jpg";
import carolinaPanthersBackground from "assets/img/teamBackground/Panthers.jpg";
import jacksonvilleJaguarsBackground from "assets/img/teamBackground/Jaguars.jpg";
import newYorkJetsBackground from "assets/img/teamBackground/Jets.jpg";
import denverBroncosBackground from "assets/img/teamBackground/Broncos.jpg";
import atlantaFalconsBackground from "assets/img/teamBackground/Falcons.jpg";
import detroitLionsBackground from "assets/img/teamBackground/Lions.jpg";
import chicagoBearsBackground from "assets/img/teamBackground/Bears.jpg";

const defaultNFLGamePassLink =
	"https://www.nflgamepass.com/en/subscribe/plans?icampaign=gpi-edi-Playoff_Selector&utm_source=NFL&utm_medium=Editorial&utm_campaign=Playoff_Selector&utm_content=Global";
const canadaNFLGamePassLink = "";

const NFLShopImagesByCountry = {
	general: defaultShopImage,
	ca: defaultShopImage,
	mx: defaultShopImage,
};

export const squadsInfoDataset = {
	/**
	 * Baltimore Ravens
	 */
	325: {
		logo: baltimoreRavensLogo,
		backgroundImage: baltimoreRavensBackground,
		runnerUpImage: baltimoreRavensImage,
		videoImage: baltimoreRavensVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/baltimore-ravens/t-36590255+z-9152346-3829362677?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Baltimore_Ravens}",
				ca: "https://www.nflshop.ca/t-3420?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Ravens",
				mx: "https://dicass.com.mx/equipos?code_0=3&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Ravens",
			},
		},
	},
	/**
	 * Buffalo Bills
	 */
	610: {
		logo: buffaloBillsLogo,
		backgroundImage: buffaloBillsBackground,
		runnerUpImage: buffaloBillsImage,
		videoImage: buffaloBillsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/buffalo-bills/t-36370290+z-9122635-3897204423?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Buffalo_Bills}",
				ca: "https://www.nflshop.ca/t-3421?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Bills",
				mx: "https://dicass.com.mx/equipos?code_0=4&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Bills",
			},
		},
	},
	/**
	 * Cleveland Browns
	 */
	1050: {
		logo: cleavelandBrownsLogo,
		backgroundImage: cleavelandBrownsBackground,
		runnerUpImage: cleavelandBrownsImage,
		videoImage: cleavelandBrownsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/cleveland-browns/t-25159183+z-70750-3323314768?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Cleveland_Browns}",
				ca: "https://www.nflshop.ca/t-3425?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Browns",
				mx: "https://dicass.com.mx/equipos?code_0=8&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Browns",
			},
		},
	},
	/**
	 * Dallas Cowboys
	 */
	1200: {
		logo: dallasCowboysLogo,
		backgroundImage: dallasCowboysBackground,
		runnerUpImage: dallasCowboysImage,
		videoImage: dallasCowboysVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/dallas-cowboys/t-36717995+z-9349787-1633145182?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Dallas_Cowboys}",
				ca: "https://www.nflshop.ca/t-3426?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Cowboys",
				mx: "https://dicass.com.mx/equipos?code_0=9&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Cowboys",
			},
		},
	},
	/**
	 * Green Bay Packers
	 */
	1800: {
		logo: greenBayPackersLogo,
		backgroundImage: greenBayPackersBackground,
		runnerUpImage: greenBayPackersImage,
		videoImage: greenBayPackersVideo,
		videoLink: canadaNFLGamePassLink,
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/green-bay-packers/t-25596865+z-9688504-2982296067?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Green_Bay_Packers}",
				ca: "https://www.nflshop.ca/t-3429?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Packers",
				mx: "https://dicass.com.mx/equipos?code_0=12&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Packers",
			},
		},
	},
	/**
	 * Tennessee Titans
	 */
	2100: {
		logo: tennesseeTitansLogo,
		backgroundImage: tennesseeTitansBackground,
		runnerUpImage: tennesseeTitansImage,
		videoImage: tennesseeTitansVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/tennessee-titans/t-36594979+z-9562652-1869101916?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Tennessee_Titans}",
				ca: "https://www.nflshop.ca/t-3452?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Titans",
				mx: "https://dicass.com.mx/equipos?code_0=30&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Titans",
			},
		},
	},
	/**
	 * Indianapolis Colts
	 */
	2200: {
		logo: indianapolisColtsLogo,
		backgroundImage: indianapolisColtsBackground,
		runnerUpImage: indianapolisColtsImage,
		videoImage: indianapolisColtsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/indianapolis-colts/t-36159267+z-9864067-435143303?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Indianapolis_Colts}",
				ca: "https://www.nflshop.ca/t-3431?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Colts",
				mx: "https://dicass.com.mx/equipos?code_0=14&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Colts",
			},
		},
	},
	/**
	 * Kansas City Chiefs
	 */
	2310: {
		logo: kansasCityCheifsLogo,
		backgroundImage: kansasCityCheifsBackground,
		runnerUpImage: kansasCityCheifsImage,
		videoImage: kansasCityCheifsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/kansas-city-chiefs/t-69157058+z-74379-2048299331?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Kansas_City_Chiefs}",
				ca: "https://www.nflshop.ca/t-3433?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Chiefs",
				mx: "https://dicass.com.mx/equipos?code_0=16&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Chiefs",
			},
		},
	},
	/**
	 * Los Angeles Rams
	 */
	2510: {
		logo: lasAngelesRamsLogo,
		backgroundImage: lasAngelesRamsBackground,
		runnerUpImage: lasAngelesRamsImage,
		videoImage: lasAngelesRamsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/los-angeles-rams/t-36049204+z-9366185-3000849326?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Los_Angeles_Rams}",
				ca: "https://www.nflshop.ca/t-3434?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Rams",
				mx: "https://dicass.com.mx/equipos?code_0=35&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Rams",
			},
		},
	},
	/**
	 * Las Vegas Raiders
	 */
	2520: {
		logo: lasVegasRaidersLogo,
		backgroundImage: lasVegasRaidersBackground,
		runnerUpImage: lasVegasRaidersImage,
		videoImage: lasVegasRaidersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/las-vegas-raiders/t-25045903+z-9049896-2398406640?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Las_Vegas_Raiders}",
				ca: "https://www.nflshop.ca/t-3443?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Raiders",
				mx: "https://dicass.com.mx/equipos?code_0=17&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Raiders",
			},
		},
	},
	/**
	 * Miami Dolphins
	 */
	2700: {
		logo: miamiDolphinsLogo,
		backgroundImage: miamiDolphinsBackground,
		runnerUpImage: miamiDolphinsImage,
		videoImage: miamiDolphinsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/miami-dolphins/t-36379261+z-9463779-2569157058?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Miami_Dolphins}",
				ca: "https://www.nflshop.ca/t-3435?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Dolphins",
				mx: "https://dicass.com.mx/equipos?code_0=19&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Dolphins",
			},
		},
	},
	/**
	 * Minnesota Vikings
	 */
	3000: {
		logo: minnesotaVikingsLogo,
		backgroundImage: minnesotaVikingsBackground,
		runnerUpImage: minnesotaVikingsImage,
		videoImage: minnesotaVikingsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/minnesota-vikings/t-14609251+z-9123207-2760977927?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Minnesota_Vikings}",
				ca: "https://www.nflshop.ca/t-3436?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Vikings",
				mx: "https://dicass.com.mx/equipos?code_0=20&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Vikings",
			},
		},
	},
	/**
	 * New England Patriots
	 */
	3200: {
		logo: newEnglandPatriotsLogo,
		backgroundImage: newEnglandPatriotsBackground,
		runnerUpImage: newEnglandPatriotsImage,
		videoImage: newEnglandPatriotsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/new-england-patriots/t-47268196+z-9025605-3179572168?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-New_England_Patriots}",
				ca: "https://www.nflshop.ca/t-3437?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Patriots",
				mx: "https://dicass.com.mx/equipos?code_0=21&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Patriots",
			},
		},
	},
	/**
	 * New Orleans Saints
	 */
	3300: {
		logo: newOrleansSaintsLogo,
		backgroundImage: newOrleansSaintsBackground,
		runnerUpImage: newOrleansSaintsImage,
		videoImage: newOrleansSaintsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/new-orleans-saints/t-58042564+z-893901-3486365028?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-New_Orleans_Saints}",
				ca: "https://www.nflshop.ca/t-3438?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Saints",
				mx: "https://dicass.com.mx/equipos?code_0=22&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Saints",
			},
		},
	},
	/**
	 * New York Giants
	 */
	3410: {
		logo: newYorkGiantsLogo,
		backgroundImage: newYorkGiantsBackground,
		runnerUpImage: newYorkGiantsImage,
		videoImage: newYorkGiantsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/new-york-giants/t-35509091+z-9322851-3483277076?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-New_York_Giants}",
				ca: "https://www.nflshop.ca/t-2512?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Giants",
				mx: "https://dicass.com.mx/equipos?code_0=23&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Giants",
			},
		},
	},
	/**
	 * Philadelphia Eagles
	 */
	3700: {
		logo: philadelphiaEaglesLogo,
		backgroundImage: philadelphiaEaglesBackground,
		runnerUpImage: philadelphiaEaglesImage,
		videoImage: philadelphiaEaglesVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/philadelphia-eagles/t-25485904+z-9971978-3310177081?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Philadelphia_Eagles}",
				ca: "https://www.nflshop.ca/t-3444?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Eagles",
				mx: "https://dicass.com.mx/equipos?code_0=25&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Eagles",
			},
		},
	},
	/**
	 * Arizona Cardinals
	 */
	3800: {
		logo: arizonaCardinalsLogo,
		backgroundImage: arizonaCardinalsBackground,
		runnerUpImage: arizonaCardinalsImage,
		videoImage: arizonaCardinalsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/arizona-cardinals/t-36045697+z-9791086-4017153551?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Arizona_Cardinals}",
				ca: "https://www.nflshop.ca/t-3418?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Cardinals",
				mx: "https://dicass.com.mx/equipos?code_0=1&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Cards",
			},
		},
	},
	/**
	 * Pittsburgh Steelers
	 */
	3900: {
		logo: pittsburghSteelersLogo,
		backgroundImage: pittsburghSteelersBackground,
		runnerUpImage: pittsburghSteelersImage,
		videoImage: pittsburghSteelersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/pittsburgh-steelers/t-47607161+z-840301-1902115047?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Pittsburgh_Steelers}",
				ca: "https://www.nflshop.ca/t-3445?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Steelers",
				mx: "https://dicass.com.mx/equipos?code_0=26&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Steelers",
			},
		},
	},
	/**
	 * San Francisco 49ers
	 */
	4500: {
		logo: sanFrancisco49ersLogo,
		backgroundImage: sanFrancisco49ersBackground,
		runnerUpImage: sanFrancisco49ersImage,
		videoImage: sanFrancisco49ersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/san-francisco-49ers/t-14933729+z-9429469-881692624?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-San_Fransisco_49ers}",
				ca: "https://www.nflshop.ca/t-3447?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-49ers",
				mx: "https://dicass.com.mx/equipos?code_0=27&utm_source=TeamPicker&utm_medium=Link&utm_campaign=49ers",
			},
		},
	},
	/**
	 * Seattle Seahawks
	 */
	4600: {
		logo: seattleSeahawksLogo,
		backgroundImage: seattleSeahawksBackground,
		runnerUpImage: seattleSeahawksImage,
		videoImage: seattleSeahawksVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/seattle-seahawks/t-14261575+z-9008694-1557674260?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Seattle_Seahawks}",
				ca: "https://www.nflshop.ca/t-3448?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Seahawks",
				mx: "https://dicass.com.mx/equipos?code_0=28&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Seahawks",
			},
		},
	},
	/**
	 * Tampa Bay Buccaneers
	 */
	4900: {
		logo: tampaBayBuccaneersLogo,
		backgroundImage: tampaBayBuccaneersBackground,
		runnerUpImage: tampaBayBuccaneersImage,
		videoImage: tampaBayBuccaneersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/tampa-bay-buccaneers/t-47049490+z-9658530-4203348785?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Tampa_Bay_Buccaneers}",
				ca: "https://www.nflshop.ca/t-3451?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Buccaneers",
				mx: "https://dicass.com.mx/equipos?code_0=29&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Bucs",
			},
		},
	},
	/**
	 * Washington
	 */
	5110: {
		logo: washingtonFootballClubLogo,
		backgroundImage: washingtonFootballClubBackground,
		runnerUpImage: washingtonFootballClubImage,
		videoImage: washingtonFootballClubVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/washington-commanders/t-14930514+z-9650960-152084737?ab={wt-static_graphic}{pt-home}{al-teamlinks}{ct-Washington_Commanders}",
				ca: "https://www.nflshop.ca/t-3453?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Washington",
				mx: "https://dicass.com.mx/equipos?code_0=31&utm_source=TeamPicker&utm_medium=Link&utm_campaign=WTeam",
			},
		},
	},
	/**
	 * Cincinnati Bengals
	 */
	920: {
		logo: cincinnatiBengalsLogo,
		backgroundImage: cincinnatiBengalsBackground,
		runnerUpImage: cincinnatiBengalsImage,
		videoImage: cincinnatiBengalsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/cincinnati-bengals/t-36370248+z-9423154-598202139?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3424?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Bengals",
				mx: "https://dicass.com.mx/equipos?code_0=7&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Bengals",
			},
		},
	},
	/**
	 * Houston Texans
	 */
	2120: {
		logo: houstonTexansLogo,
		backgroundImage: houstonTexansBackground,
		runnerUpImage: houstonTexansImage,
		videoImage: houstonTexansVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/houston-texans/t-36713655+z-9494636-4253018096?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3430?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Texans",
				mx: "https://dicass.com.mx/equipos?code_0=13&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Texans",
			},
		},
	},
	/**
	 * Los Angeles Chargers
	 */
	4400: {
		logo: losAngelesChargersLogo,
		backgroundImage: losAngelesChargersBackground,
		runnerUpImage: losAngelesChargersImage,
		videoImage: losAngelesChargersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/los-angeles-chargers/t-21441805+z-9001241-2526666002?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-9075?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Chargers",
				mx: "https://dicass.com.mx/equipos?code_0=18&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Chargers",
			},
		},
	},
	/**
	 * Carolina Panthers
	 */
	750: {
		logo: carolinaPanthersLogo,
		backgroundImage: carolinaPanthersBackground,
		runnerUpImage: carolinaPanthersImage,
		videoImage: carolinaPanthersVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/carolina-panthers/t-36484679+z-9917544-2409711364?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3422?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Panthers",
				mx: "https://dicass.com.mx/equipos?code_0=5&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Panthers",
			},
		},
	},
	/**
	 * Jacksonville Jaguars
	 */
	2250: {
		logo: jacksonvilleJaguarsLogo,
		backgroundImage: jacksonvilleJaguarsBackground,
		runnerUpImage: jacksonvilleJaguarsImage,
		videoImage: jacksonvilleJaguarsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/jacksonville-jaguars/t-14371424+z-9736823-4280408112?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3432?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Jaguars",
				mx: "https://dicass.com.mx/equipos?code_0=15&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Jaguars",
			},
		},
	},
	/**
	 * New York Jets
	 */
	3430: {
		logo: newYorkJetsLogo,
		backgroundImage: newYorkJetsBackground,
		runnerUpImage: newYorkJetsImage,
		videoImage: newYorkJetsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/new-york-jets/t-81932633+z-839015-3582935029?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3440?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Jets",
				mx: "https://dicass.com.mx/equipos?code_0=24&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Jets",
			},
		},
	},
	/**
	 * Denver Broncos
	 */
	1400: {
		logo: denverBroncosLogo,
		backgroundImage: denverBroncosBackground,
		runnerUpImage: denverBroncosImage,
		videoImage: denverBroncos,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/denver-broncos/t-36822485+z-9547652-3724901295?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3427?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Broncos",
				mx: "https://dicass.com.mx/equipos?code_0=10&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Broncos",
			},
		},
	},
	/**
	 * Atlanta Falcons
	 */
	200: {
		logo: atlantaFalconsLogo,
		backgroundImage: atlantaFalconsBackground,
		runnerUpImage: atlantaFalconsImage,
		videoImage: atlantaFalconsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/atlanta-falcons/t-25269009+z-846076-1712408145?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3419?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Falcons",
				mx: "https://dicass.com.mx/equipos?code_0=2&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Falcons",
			},
		},
	},
	/**
	 * Detroit Lions
	 */
	1540: {
		logo: detroitLionsLogo,
		backgroundImage: detroitLionsBackground,
		runnerUpImage: detroitLionsImage,
		videoImage: detroitLionsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/detroit-lions/t-36377931+z-9432566-482725144?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3428?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Lions",
				mx: "https://dicass.com.mx/equipos?code_0=11&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Lions",
			},
		},
	},
	/**
	 * Chicago Bears
	 */
	810: {
		logo: chicagoBearsLogo,
		backgroundImage: chicagoBearsBackground,
		runnerUpImage: chicagoBearsImage,
		videoImage: chicagoBearsVideo,
		videoLink: "",
		NFLGamePass: {
			image: {
				general: defaultGamePassImage,
			},
			link: {
				general: defaultNFLGamePassLink,
				ca: canadaNFLGamePassLink,
			},
		},
		NFLShop: {
			image: NFLShopImagesByCountry,
			link: {
				general:
					"https://www.nflshop.com/chicago-bears/t-14716836+z-9525984-212468155?_ref=m-TOPNAV",
				ca: "https://www.nflshop.ca/t-3423?cid=TP457031&_s=bm-nflcom-CAN-teamquizpromo-2020-Bears",
				mx: "https://dicass.com.mx/equipos?code_0=6&utm_source=TeamPicker&utm_medium=Link&utm_campaign=Bears",
			},
		},
	},
};

export default squadsInfoDataset;
