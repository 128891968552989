import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import React, {StrictMode} from "react";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import smoothScroll from "smoothscroll-polyfill";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {StoreProvider, RootStore} from "modules/stores";
import Home from "pages/Home";
import {noop} from "lodash";

smoothScroll.polyfill();

let errorCount = 0;
const MAX_ERRORS = 100;
const ignoreErrors = ["Can't find variable: _AutofillCallbackHandler"];
const ignoredWords = ["googletag"];

Sentry.init({
	dsn:
		"https://b663067f0c6b47ac83e4d446bf5aac9a@o151969.ingest.sentry.io/5519847",
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message) {
			if (ignoreErrors.includes(event.message)) {
				return null;
			}

			const hasIgnored =
				ignoredWords.filter((word) => event.message?.includes(word))
					.length > 0;
			if (hasIgnored) {
				return null;
			}
		}
		return event;
	},
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	allowUrls: [".fanhubmedia.com", "nflteampicker.nfl.com"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	tracesSampleRate: 0.1,
});

window.customGTagTrack = noop;

const rootStore = new RootStore();

ReactDOM.render(
	<StrictMode>
		<StoreProvider store={rootStore}>
			<Home />
		</StoreProvider>
	</StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
