import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {QuestionType} from "modules/constant";
import {QuestionText} from "./QuestionText";
import {QuestionGeneral} from "./QuestionGeneral";
import {QuestionSlider} from "./QuestionSlider";
import {QuestionSponsored} from "./QuestionSponsored";
import {FinalScreen} from "./FinalScreen";
import {TrackQuestionViewingState} from "./common";
import {Exist} from "components/Exist";
import {track} from "modules/utils";
import {ResultScreen} from "./ResultScreen";

const {General, Slider, Sponsored, Text} = QuestionType;

const questionMap = new Map([
	[Text, QuestionText],
	[Slider, QuestionSlider],
	[Sponsored, QuestionSponsored],
	[General, QuestionGeneral],
]);

export const Questions: React.FC = observer(() => {
	const {questions, UIQuestionsProgress, UISquadList} = useStore();
	const {finalScreenID, isAllQuestionsAnswered, resultScreenID} =
		UIQuestionsProgress;

	useEffect(() => {
		void questions.request();
	}, [questions]);

	useEffect(() => {
		if (isAllQuestionsAnswered) {
			track({
				linkType: "quiz complete",
				linkName: UISquadList.topSquad?.full_name,
				quiz_complete: true,
			});
		}
	}, [isAllQuestionsAnswered, UISquadList]);

	const questionsList = UIQuestionsProgress.progressItems.map((question) => {
		const {type, id} = question;
		const Component = questionMap.get(type);

		return Component ? (
			<TrackQuestionViewingState key={id} id={question.id}>
				<Component {...question} />
			</TrackQuestionViewingState>
		) : null;
	});

	return (
		<React.Fragment>
			{questionsList}
			<Exist when={isAllQuestionsAnswered}>
				<TrackQuestionViewingState id={resultScreenID}>
					<ResultScreen />
				</TrackQuestionViewingState>
				<TrackQuestionViewingState id={finalScreenID}>
					<FinalScreen />
				</TrackQuestionViewingState>
			</Exist>
		</React.Fragment>
	);
});
