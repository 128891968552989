import {action, computed, makeObservable, observable, values} from "mobx";
import {RootStore} from "modules/stores/RootStore";
import {
	filter,
	findIndex,
	isEmpty,
	isUndefined,
	keyBy,
	size,
	toLower,
} from "lodash";
import {LoadState, QuestionType} from "modules/constant";
import {Api, createConnextraScriptTag, track} from "modules/utils";

export class UIQuestionsProgress<TModel extends RootStore> {
	finalScreenID = Number.MAX_SAFE_INTEGER;
	resultScreenID = Number.MAX_SAFE_INTEGER - 1;

	@observable isMenuOpen: boolean = false;
	@observable answers: Record<string, number> = {};
	@observable viewedQuestionID?: number;
	@observable activeQuestionIndex: number = 0;
	@observable isJebbitComplete: boolean = false;

	constructor(
		private readonly rootStore: Pick<
			TModel,
			"questions" | "region" | "fingerprint"
		>
	) {
		makeObservable(this);
	}

	@action toggleMenu(isOpen?: boolean) {
		if (isOpen !== undefined) {
			this.isMenuOpen = isOpen;
		} else {
			this.isMenuOpen = !this.isMenuOpen;
		}
	}

	@computed get allItems() {
		const region = this.rootStore.region;

		if (region.apiState !== LoadState.Received) {
			return [];
		}

		const country = region.country;

		const questions = this.rootStore.questions.entities.filter(
			({country_include, country_exclude}) => {
				const allowed = (country_include || []).map(toLower);
				const denied = (country_exclude || []).map(toLower);

				if (isEmpty(allowed)) {
					return true;
				}

				if (allowed.includes("all")) {
					return !denied.includes(country);
				}

				return allowed.includes(country);
			}
		);

		const activeIndex = this.activeQuestionIndex;

		return questions.map((question, index) => {
			const hasAnswer = !isUndefined(this.answers[question.id]);
			const isActive = activeIndex === index;
			const questionNumber = index + 1;

			return {hasAnswer, isActive, questionNumber, ...question};
		});
	}

	@computed get isEmpty() {
		return isEmpty(this.allItems);
	}

	@computed get noOfQuestions() {
		return size(this.allItems);
	}

	@computed get allItemsByID() {
		return keyBy(this.allItems, "id");
	}

	@computed get progressItems() {
		const maxIndex = size(filter(this.allItems, {hasAnswer: true}));
		return this.allItems.filter((_, index) => index <= maxIndex);
	}

	@computed get isAllQuestionsAnswered() {
		return (
			!isEmpty(this.allItems) &&
			this.allItems.every(({hasAnswer}) => hasAnswer)
		);
	}

	@computed get isAnyQuestionAnswered() {
		return (
			!isEmpty(this.allItems) &&
			this.allItems.some(({hasAnswer}) => hasAnswer)
		);
	}

	@computed get isScrollCTAVisible() {
		if (this.viewedQuestionID === this.resultScreenID) {
			return true;
		}
		return Boolean(
			this.viewedQuestionID && this.answers[this.viewedQuestionID]
		);
	}

	@computed private get nextQuestionID() {
		const nextQuestionIndex =
			findIndex(this.allItems, {id: this.viewedQuestionID}) + 1;
		if (nextQuestionIndex > 0 && this.allItems[nextQuestionIndex]?.id) {
			return this.allItems[nextQuestionIndex]?.id;
		}
		return this.resultScreenID;
	}

	scrollToNextScreen() {
		document
			.getElementById(`question-${this.nextQuestionID}`)
			?.scrollIntoView({
				block: "start",
				behavior: "smooth",
			});
	}

	@action selectAnswer(questionID: number, optionID: number) {
		const currentQuestionIndex = findIndex(this.allItems, {id: questionID});
		const nextQuestionIndex = currentQuestionIndex + 1;

		track({
			linkType: "quiz",
			linkName: `Question ${currentQuestionIndex + 1}`,
		});

		if (currentQuestionIndex === 0) {
			createConnextraScriptTag(
				"https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_quizstart"
			);
		}

		if (
			!this.activeQuestionIndex ||
			this.activeQuestionIndex < nextQuestionIndex
		) {
			this.activeQuestionIndex = nextQuestionIndex;
		}

		if (this.answers[questionID] !== optionID) {
			this.answers[questionID] = optionID;
			setTimeout(() => this.scrollToNextScreen(), 500);
		}

		if (this.isAllQuestionsAnswered) {
			this.saveAnswers();
		}
	}

	saveAnswers() {
		const user_key = this.rootStore.fingerprint.visitorId;
		const payload = {
			user_key,
			answers: values(this.answers).map((option_id) => ({
				option_id,
			})),
		};

		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_quizconfirm?AccountID=${user_key}`
		);

		void Api.Answers.save(payload);
	}

	@action setViewedQuestion(questionID: number) {
		this.viewedQuestionID = questionID;
	}

	@computed get isLogoVisible() {
		const viewedQuestionID = this.viewedQuestionID || this.allItems[0].id;
		if (viewedQuestionID === this.finalScreenID) {
			return true;
		}
		if (viewedQuestionID === this.resultScreenID) {
			return false;
		}
		return this.allItemsByID[viewedQuestionID].type !== QuestionType.Slider;
	}

	@action resetProgress() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		this.answers = {};
	}
}
