import {Language} from "./enum";
import {SessionStorage} from "modules/utils/Storage";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";
export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

const SELECTED_LANG = SessionStorage.GET("language");

export const LOCALE = (() => {
	if (SELECTED_LANG) {
		return SELECTED_LANG;
	}

	const userLang = (navigator?.languages[0] || navigator.language).split(
		"-"
	)[0];
	const allowedLanguages = Object.values<string>(Language);

	return allowedLanguages.includes(userLang) ? userLang : Language.EN;
})();

export const LANGUAGES = [
	{id: 1, title: "English", value: Language.EN},
	{id: 2, title: "German", value: Language.DE},
	{id: 3, title: "Spanish", value: Language.ES},
	{id: 4, title: "French", value: Language.FR},
	{id: 5, title: "Portuguese", value: Language.PT},
];

export * from "./enum";
