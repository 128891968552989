import {isEmpty, get} from "lodash";
import {IApiResponse} from "modules/types";
import * as Sentry from "@sentry/react";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	public static INVALID_CREDENTIALS = 403;
	public static USER_NOT_REGISTERED = 513;

	public static HAS_ERROR(payload: IApiResponse) {
		try {
			const errors = payload?.errors || {};
			return (
				!isEmpty(errors) || Boolean(Array.isArray(errors) && errors.length)
			);
		}catch (e) {
			Sentry.captureException(payload);
			return false;
		}
	}

	public static CHECK(response: IApiResponse) {
		if (ApiError.HAS_ERROR(response)) {
			const text = get(
				response,
				"errors[0]",
				get(response, "errors.message")
			) as string;

			throw new ApiError(text, response.code);
		}

		return response;
	}

	public code?: number;
	public text?: string;

	constructor(text: string, code?: number) {
		super(text);
		this.name = "API Error";
		this.text = text;
		this.code = code;
	}
}
