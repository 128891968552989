import React, {useState} from "react";
import styled from "styled-components";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import {Button} from "components/Button";
import shopLogo from "assets/img/finalScreen/nflShopLogo.png";
import {LoadState} from "modules/constant";
import {Exist} from "components/Exist";

const Wrapper = styled.div`
	flex: 1;
	padding: 24px 32px;
	border-radius: 4px;
	border: 2px solid #6d747d;
	background-color: rgba(18, 18, 18, 0.9);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
`;

const Content = styled.form`
	h4 {
		color: #fff;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		line-height: 140%;
		letter-spacing: 2px;
		margin-bottom: 12px;
	}

	.MuiFormControl-root {
		margin-bottom: 8px;
	}

	.MuiFormGroup-root {
		margin-bottom: 20px;
	}

	.MuiFormLabel-root {
		font-family: "AllProDisplayC", Arial, sans-serif;
	}

	.MuiFormControlLabel-label {
		font-family: "AllProDisplayC", Arial, sans-serif;
		font-size: 12px;
		line-height: 135%; /* 16.2px */
		letter-spacing: 1px;
	}

	.MuiFilledInput-root {
		background-color: #fff;
		font-family: "AllProDisplayC", Arial, sans-serif;
	}

	.MuiFilledInput-root:after {
		border-bottom-color: #09349f;
	}

	.Mui-focused {
		color: #09349f;
	}

	.MuiCheckbox-root,
	.MuiCheckbox-root.Mui-checked {
		color: #fff;
	}

	button:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;

const ErrorMessage = styled.p`
	color: red;
	font-size: 14px;
	text-align: center;
	margin-top: 10px;
`;

const OPT_OUT_MARKETS = ["mx", "us"];

export const ShopForm: React.FC = observer(() => {
	const {I18n, UISubscriptionForm, region} = useStore();
	const [email, setEmail] = useState("");
	const defaultTerms = !OPT_OUT_MARKETS.includes(region.country);
	const [terms, setTerms] = useState(defaultTerms);
	const isDisabled =
		!email || !terms || region.apiState === LoadState.Requested;
	const error = region.error;

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleChangeTerms = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setTerms(checked);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		UISubscriptionForm.addSubscription(email);
	};

	return (
		<Wrapper>
			<Content onSubmit={handleSubmit}>
				<h4>{I18n.t("shop.form.title")}</h4>
				<TextField
					fullWidth
					required
					variant="filled"
					label={I18n.t("shop.form.email")}
					value={email}
					onChange={handleChangeEmail}
					type="email"
				/>
				<FormGroup>
					<FormControlLabel
						required
						value={terms}
						control={<Checkbox onChange={handleChangeTerms} />}
						label={I18n.t("shop.form.terms")}
					/>
				</FormGroup>
				<Button disabled={isDisabled} type="submit">
					{I18n.t("shop.form.button")}
				</Button>
				<Exist when={Boolean(error)}>
					<ErrorMessage>{error}</ErrorMessage>
				</Exist>
			</Content>

			<img src={shopLogo} alt="shop" height="40" />
		</Wrapper>
	);
});
