import {makeObservable, observable} from "mobx";
import {RootStore} from "modules/stores/RootStore";
import {Api} from "modules/utils";

export class UISubscriptionForm<TModel extends RootStore> {
	@observable isSubscribed: boolean = false;

	constructor(private readonly rootStore: Pick<TModel, "fingerprint">) {
		makeObservable(this);
	}

	addSubscription(email: string) {
		const user_key = this.rootStore.fingerprint.visitorId;
		const payload = {
			user_key,
			email,
			type: "marketing",
		};

		void Api.Subscription.add(payload).then(() => {
			this.isSubscribed = true;
		});
	}
}
