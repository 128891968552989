import React, {useState, useRef} from "react";
import {IQuestion} from "modules/stores/Models";
import {
	QuestionWrapper,
	QuestionTitle,
	QuestionContainer,
	QuestionCounter,
} from "./common";
import styled from "styled-components";

import {Exist} from "components/Exist";
import backgroundGeneralQuestion from "assets/img/backgroundGeneralQuestion.png";
import {useStore, useSlider} from "modules/hooks";
import {IBGImageProps} from "modules/types";
import {getBGImage} from "modules/utils";
import {IMAGES_URL} from "modules/constant";
import {IconArrow} from "components/Icons";

interface IWrapperProps {
	background: null | string;
}

const getWrapperBackground = ({background}: IWrapperProps) =>
	background ? `${IMAGES_URL}image/${background}` : backgroundGeneralQuestion;

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	background: url(${getWrapperBackground}) no-repeat center center / cover;
`;

const OptionsList = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	overflow: hidden;
	scroll-snap-type: x mandatory;
	flex-wrap: wrap;
`;

const Figure = styled.div<IBGImageProps>`
	background: ${getBGImage};
	margin-bottom: 20px;
	width: 100%;
	height: 320px;
	box-sizing: border-box;
	border: 1px solid #fff;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	transition: all 0.5s;

	img {
		display: block;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 1000px) {
		height: 120px;
		margin-bottom: 10px;
	}
`;

const FigureCaption = styled.div`
	letter-spacing: 2px;
	text-transform: uppercase;
	transition: background 0.5s;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		)
		no-repeat bottom center;
	background-size: 0 1px;
	padding-bottom: 20px;
	display: inline-block;
	color: #000;

	@media (max-width: 1000px) {
		font-size: 12px;
		padding-bottom: 10px;
	}
`;

const Option = styled.div`
	min-width: 260px;
	width: 260px;
	text-align: center;
	cursor: pointer;
	transition: all 0.5s;
	background-color: #fff;
	margin: 0 5% 5% 0;

	@media (max-width: 1000px) {
		min-width: 120px;
		width: 120px;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		${Figure} {
			box-shadow: inset 0 0 0 5px #fff;
		}

		${FigureCaption} {
			background-size: 100% 1px;
			font-weight: 600;
		}
	}

	&.fade {
		opacity: 0.5;
	}
`;

const WrapperScrollNaw = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
`;

const ScrollButton = styled.button`
	background: #fff;
	border: none;
	width: 40px;
	height: 40px;
	cursor: pointer;

	&:disabled {
		background: #555555;
	}

	${IconArrow} {
		font-size: 11px;
		margin-left: 10px;
		&:before,
		&:after {
			background: #000000;
		}
	}
`;

const ScrollRight = styled(ScrollButton)`
	margin-left: 10px;

	${IconArrow} {
		transform: rotate(-88deg);
	}
`;

const ScrollLeft = styled(ScrollButton)`
	${IconArrow} {
		margin-left: -10px;
		transform: rotate(88deg);
	}
`;

const getStateClass = (currentID: number, selectedID: number | null) => {
	if (selectedID === null) {
		return "";
	}

	if (currentID === selectedID) {
		return "active";
	}

	return "fade";
};

export const QuestionGeneral: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	background,
	questionNumber,
}) => {
	const {UIQuestionsProgress} = useStore();
	const [selectedID, setSelectedID] = useState<null | number>(null);
	const element = useRef<HTMLDivElement>(null);
	// const isMobile = useMediaQuery("(max-width: 1000px)");
	const slider = useSlider(element);
	// const isSliderVisible = Boolean(!isMobile && slider && slider.is_show_nav);
	const isSliderVisible = false;
	const noOfQuestions = UIQuestionsProgress.noOfQuestions;

	return (
		<Wrapper background={background}>
			<QuestionContainer>
				<QuestionCounter>
					{questionNumber}/{noOfQuestions}
				</QuestionCounter>
				<QuestionTitle>{title}</QuestionTitle>
				<OptionsList ref={element}>
					{options.map(({id, title, image}) => (
						<Option
							key={id}
							className={getStateClass(id, selectedID)}
							onClick={() => {
								UIQuestionsProgress.selectAnswer(
									questionID,
									id
								);
								setSelectedID(id);
							}}
						>
							<Figure imageSrc={image} />
							<FigureCaption>{title}</FigureCaption>
						</Option>
					))}
				</OptionsList>
				<Exist when={isSliderVisible}>
					<WrapperScrollNaw>
						<ScrollLeft
							onClick={slider?.scrollLeft}
							disabled={slider?.is_disabled_left}
						>
							<IconArrow />
						</ScrollLeft>
						<ScrollRight
							onClick={slider?.scrollRight}
							disabled={slider?.is_disabled_right}
						>
							<IconArrow />
						</ScrollRight>
					</WrapperScrollNaw>
				</Exist>
			</QuestionContainer>
		</Wrapper>
	);
};
